.field {
	@apply flex h-auto w-full items-center justify-center;
}

.field-input-container {
	@apply flex h-auto w-full max-w-[350px] flex-col flex-wrap items-center gap-x-2 gap-y-4;
}

.field__input {
	@apply h-[75px] w-full animate-fadein rounded-[3px] border-[1px] px-2 font-roboto text-[1.8rem] font-light text-black outline-none;
}

.field-checkbox-container {
	@apply flex h-auto w-full max-w-[350px] flex-wrap items-center justify-center gap-x-2;
}

.field__checkbox {
	@apply aspect-square scale-150;
}

.field-content-container {
	@apply h-auto w-full max-w-[400px];
}

.field__content {
	@apply animate-fadein p-5;
}

.field-button-container {
	@apply flex h-auto w-full max-w-[320px] flex-wrap justify-center gap-4 sm:max-w-[620px];
}

.field__button {
	@apply w-full min-w-[150px] animate-fadein rounded-xl bg-green px-6 py-5 text-lg font-bold text-white sm:w-[40%] sm:px-12;
}

.field__input--error {
	@apply border-red-500;
}

.field-label {
	@apply animate-fadein text-sm font-bold;
}
