@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	/* lato-100 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Lato';
		font-style: normal;
		font-weight: 100;
		src: url('./fonts/lato/lato-v24-latin-100.eot'); /* IE9 Compat Modes */
		src: url('./fonts/lato/lato-v24-latin-100.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/lato/lato-v24-latin-100.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/lato/lato-v24-latin-100.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/lato/lato-v24-latin-100.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/lato/lato-v24-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
	}
	/* lato-300 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Lato';
		font-style: normal;
		font-weight: 300;
		src: url('./fonts/lato/lato-v24-latin-300.eot'); /* IE9 Compat Modes */
		src: url('./fonts/lato/lato-v24-latin-300.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/lato/lato-v24-latin-300.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/lato/lato-v24-latin-300.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/lato/lato-v24-latin-300.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/lato/lato-v24-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
	}
	/* lato-regular - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Lato';
		font-style: normal;
		font-weight: 400;
		src: url('./fonts/lato/lato-v24-latin-regular.eot'); /* IE9 Compat Modes */
		src: url('./fonts/lato/lato-v24-latin-regular.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/lato/lato-v24-latin-regular.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/lato/lato-v24-latin-regular.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/lato/lato-v24-latin-regular.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/lato/lato-v24-latin-regular.svg#Lato')
				format('svg'); /* Legacy iOS */
	}
	/* lato-700 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Lato';
		font-style: normal;
		font-weight: 700;
		src: url('./fonts/lato/lato-v24-latin-700.eot'); /* IE9 Compat Modes */
		src: url('./fonts/lato/lato-v24-latin-700.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/lato/lato-v24-latin-700.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/lato/lato-v24-latin-700.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/lato/lato-v24-latin-700.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/lato/lato-v24-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
	}
	/* lato-900 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Lato';
		font-style: normal;
		font-weight: 900;
		src: url('./fonts/lato/lato-v24-latin-900.eot'); /* IE9 Compat Modes */
		src: url('./fonts/lato/lato-v24-latin-900.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/lato/lato-v24-latin-900.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/lato/lato-v24-latin-900.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/lato/lato-v24-latin-900.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/lato/lato-v24-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
	}

	/* roboto-100 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 100;
		src: url('./fonts/roboto/roboto-v30-latin-100.eot'); /* IE9 Compat Modes */
		src: url('./fonts/roboto/roboto-v30-latin-100.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/roboto/roboto-v30-latin-100.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-100.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/roboto/roboto-v30-latin-100.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/roboto/roboto-v30-latin-100.svg#Roboto')
				format('svg'); /* Legacy iOS */
	}
	/* roboto-300 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 300;
		src: url('./fonts/roboto/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
		src: url('./fonts/roboto/roboto-v30-latin-300.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/roboto/roboto-v30-latin-300.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-300.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/roboto/roboto-v30-latin-300.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/roboto/roboto-v30-latin-300.svg#Roboto')
				format('svg'); /* Legacy iOS */
	}
	/* roboto-regular - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 400;
		src: url('./fonts/roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
		src: url('./fonts/roboto/roboto-v30-latin-regular.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/roboto/roboto-v30-latin-regular.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-regular.woff')
				format('woff'),
			/* Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-regular.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/roboto/roboto-v30-latin-regular.svg#Roboto')
				format('svg'); /* Legacy iOS */
	}
	/* roboto-500 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		src: url('./fonts/roboto/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
		src: url('./fonts/roboto/roboto-v30-latin-500.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/roboto/roboto-v30-latin-500.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-500.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/roboto/roboto-v30-latin-500.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/roboto/roboto-v30-latin-500.svg#Roboto')
				format('svg'); /* Legacy iOS */
	}
	/* roboto-700 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		src: url('./fonts/roboto/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
		src: url('./fonts/roboto/roboto-v30-latin-700.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/roboto/roboto-v30-latin-700.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-700.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/roboto/roboto-v30-latin-700.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/roboto/roboto-v30-latin-700.svg#Roboto')
				format('svg'); /* Legacy iOS */
	}
	/* roboto-900 - latin */
	@font-face {
		font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 900;
		src: url('./fonts/roboto/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
		src: url('./fonts/roboto/roboto-v30-latin-900.eot?#iefix')
				format('embedded-opentype'),
			/* IE6-IE8 */ url('./fonts/roboto/roboto-v30-latin-900.woff2')
				format('woff2'),
			/* Super Modern Browsers */
				url('./fonts/roboto/roboto-v30-latin-900.woff') format('woff'),
			/* Modern Browsers */ url('./fonts/roboto/roboto-v30-latin-900.ttf')
				format('truetype'),
			/* Safari, Android, iOS */
				url('./fonts/roboto/roboto-v30-latin-900.svg#Roboto')
				format('svg'); /* Legacy iOS */
	}
}

@layer base {
	html {
		font-family: 'Lato', system-ui, sans-serif;
	}
}
