.step {
	@apply flex w-full max-w-5xl animate-fadein flex-col items-center text-white;
}

.step__title {
	@apply mb-6 mt-4 px-[.625rem] text-2xl font-semibold leading-6 md:px-5 md:text-3xl md:font-normal;
}

.step__field-container {
	@apply flex w-full flex-col items-center gap-y-[1.875rem] px-5;
}
